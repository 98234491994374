import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import appReducer from './reducers/app';
import footerReducer from './reducers/footer';
import thunkMiddleware from 'redux-thunk';

const loggerMiddleware = createLogger();

const rootReducer = combineReducers({
  app: appReducer,
  footer: footerReducer,
});

const configureStore = (initialState) => {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware)),
  );
};

export default configureStore;
